import { z } from 'zod'
import { WIDGET_TYPES } from '@/constants'

export const WidgetAttributesSchema = z.object({
  // NOTE: This means that only "Appointments" and "Schedules" are allowed widget types, case sensitive
  widgetType: z.enum([
    WIDGET_TYPES.Appointments.type,
    WIDGET_TYPES.Schedules.type,
  ]),
  widgetId: z.string(),
  preview: z.string().nullable(),
})
