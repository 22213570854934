import { IFRAME_LOADER_CLASS } from '@/constants'
import loadingGif from '@/img/loader.gif'

export function showIframeLoader(widgetSnippet: Element) {
  const loader = document.createElement('div')

  loader.className = IFRAME_LOADER_CLASS
  loader.style.display = 'flex'
  loader.style.justifyContent = 'center'
  loader.style.alignItems = 'center'
  loader.style.width = '100%'
  loader.style.padding = '50px'

  const img = document.createElement('img')

  img.src = loadingGif

  img.alt = 'Loading...'
  img.style.width = '50px'
  img.style.height = '50px'

  loader.appendChild(img)
  widgetSnippet.appendChild(loader)
}

export function hideIframeLoader(widgetSnippet: Element) {
  const loader = widgetSnippet.querySelector(`.${IFRAME_LOADER_CLASS}`)

  if (loader) {
    loader.remove()
  }
}
