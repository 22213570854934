import { DEFAULT_ERROR_MESSAGE } from '@/constants'
import { WidgetAttributesSchema } from '@/schemas/WidgetAttributesSchema'

export function extractAttributes(element: Element) {
  const widgetType = element.getAttribute('data-widget-type')
  const widgetId = element.getAttribute('data-widget-id')
  const preview = element.getAttribute('data-preview')

  const widgetAttributes = WidgetAttributesSchema.safeParse({
    widgetType,
    widgetId,
    preview,
  })

  if (!widgetAttributes.success) {
    // TODO: Add New Relic logging
    // eslint-disable-next-line no-console
    console.warn(DEFAULT_ERROR_MESSAGE)
    return
  }

  return widgetAttributes.data
}
