import { UNIQUE_WINDOW_IDENTIFIER } from '../constants'

// TODO: Add tests for this utility since it is a key part of the bootstrapper/customer page interaction
// When multiple widgets (and multiple bootstrapper scripts) are loaded on the same page, this will make sure only one script is responsible for injecting the Next.js iFrames
export function setAndCheckUniqueIdentifier() {
  if (window[UNIQUE_WINDOW_IDENTIFIER]) {
    return true
  }

  window[UNIQUE_WINDOW_IDENTIFIER] = true
  return false
}
