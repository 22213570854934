import {
  DEFAULT_ERROR_MESSAGE,
  IFRAME_URL,
  SNIPPET_SELECTOR,
} from '@/constants'
import { createIframe } from '@/utilities/createIframe'
import { extractAttributes } from '@/utilities/extractAttributes'
import { injectIframe } from '@/utilities/injectIframe'

export function initIframeInjection() {
  const foundWidgetSnippets = [...document.querySelectorAll(SNIPPET_SELECTOR)]

  if (foundWidgetSnippets.length === 0) {
    // TODO: Create and use `logError` utility instead...
    // ... & consider surfacing additional details in non-PROD environments
    // TODO: Add New Relic logging
    // eslint-disable-next-line no-console
    console.warn(DEFAULT_ERROR_MESSAGE)
    return
  }

  foundWidgetSnippets.forEach((widgetSnippet) => {
    const widgetAttributes = extractAttributes(widgetSnippet)

    if (!widgetAttributes) {
      // TODO: Add New Relic logging
      // eslint-disable-next-line no-console
      console.warn(DEFAULT_ERROR_MESSAGE)
      return
    }

    const iframe = createIframe(widgetAttributes, IFRAME_URL)
    injectIframe(iframe, widgetSnippet)
  })
}
