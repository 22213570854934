import { ACCEPTED_UTM_PARAMS } from '@/constants'

export function queryUtmParametersFromParent(): URLSearchParams {
  const parentPageSearchParams = new URLSearchParams(window.location.search)
  const filteredParams = new URLSearchParams()

  // Iterate over each query parameter
  for (const [key, value] of parentPageSearchParams.entries()) {
    // Check if the key is in the list of expected keys
    if (ACCEPTED_UTM_PARAMS.includes(key)) {
      filteredParams.append(key, value)
    }
  }

  return filteredParams
}
