import { addIframeStyles } from '@/utilities/addIframeStyles'
import { initIframeInjection } from '@/utilities/initIframeInjection'
import { setAndCheckUniqueIdentifier } from '@/utilities/setAndCheckUniqueIdentifier'

;(function () {
  const scriptAlreadyPresent = setAndCheckUniqueIdentifier()
  if (scriptAlreadyPresent) {
    // TODO: Add New Relic logging (multiple widgets on the same page scenario)
    return
  }

  addIframeStyles()

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initIframeInjection)
  } else {
    initIframeInjection()
  }
})()
