import { iframeResizer } from 'iframe-resizer'
import { hideIframeLoader, showIframeLoader } from './iframeLoader'

export function injectIframe(
  iframe: HTMLIFrameElement,
  widgetSnippet: Element,
) {
  showIframeLoader(widgetSnippet)
  widgetSnippet.appendChild(iframe)
  iframeResizer(
    {
      log: false,
      initCallback: () => {
        hideIframeLoader(widgetSnippet)
      },
    },
    `#${widgetSnippet.querySelector('iframe')?.id}`,
  )
}
